/**
 * @license
 * Copyright 2020 Google Inc.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

canvas {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1000;
  touch-action: none;
}

main {
  /* Only to allow clicks on the canvas to go through when the editor is open. */
  position: absolute;
}

details {
  padding: 8px;
}

details[open] {
  padding-bottom: 32px;
}

details,
.palette {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: monospace;
  width: fit-content;
}

details > summary {
  cursor: pointer;
  list-style: none;
  display: inline-block;
}

details > summary::-webkit-details-marker {
  display: none;
}

details > summary::before {
  content: '[+]';
}

details[open] > summary::before {
  content: '[-] ';
}

.state-editor {
  margin: 8px 0;
  display: flex;
}

input,
button {
  font: inherit;
  background: rgba(0, 0, 0, 0.25);
  border: none;
  color: inherit;
  line-height: 24px;
  padding: 0 8px;
}

input[type='text'] {
  width: 64px;
}

input[type='color'] {
  /* Really 24x24 */
  height: 22px;
  width: 20px;
  border: none;
  background: transparent;
}

input[type='color']::-webkit-color-swatch-wrapper {
  padding: 0;
}

input[type='color']::-webkit-color-swatch {
  border: none;
}

.rule-editor {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.rule-editor select {
  width: 64px;
  color: inherit;
  font: inherit;
  background: rgba(0, 0, 0, 0.25);
  border: none;
  line-height: 24px;
  -webkit-appearance: none;
  text-shadow: 1px 1px 2px black;
}

.rule-editor select option {
  background: rgba(0, 0, 0, 0.75);
}

.palette {
  position: fixed;
  right: 8px;
  top: 8px;
  display: flex;
  flex-direction: column;
}

.palette input {
  display: none;
}

.palette input + div {
  text-shadow: 1px 1px 2px black;
  padding: 8px;
}

.palette input:checked + div {
  box-shadow: 0 0 0 3px red inset;
}
